<template>
  <div class="content">
    <Nav></Nav>
    <Navbj></Navbj>

    <div class="cont_inform">
      <div @click="$router.back()"> <i class="el-icon-arrow-left"></i>
        <span v-if="type == 1">
           返回&nbsp;&nbsp;冥想资讯
        </span>
       <span v-else>
          返回&nbsp;&nbsp;官方活动
       </span>

      </div>
    </div>
    <div class="cont_policy">
      <div class="cont_title">{{list.title}}</div>
      <div class="cont_time">{{list.addtime | formatDateAt}}</div>
      <div class="cont_info" v-html="list.cont"></div>
<!--      <div class="about_xz">-->
<!--        <img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/21.png" alt="">-->
<!--      </div>-->
    </div>

    <Btm></Btm>
  </div>
</template>

<script>
import Nav from "@/components/yd-Nav/index"
import Navbj from "@/components/yd-Nav/nav_bj"
import Btm from "@/components/yd-Btmfooter/index"
import {mes_classify_list_xq,gfhd_list_xq} from "@/api/request/all";
import {mapState} from "vuex";
export default {
  name: "mesdetail",
  data(){
    return{
     id:this.$route.params.id,
     type:this.$route.params.type,
      list:''
    }
  },
  computed: {
    ...mapState(['user']),
  },
  components:{
    Nav,
    Navbj,
    Btm
  },
  metaInfo() {
    return {
      title: this.list.title+'-流静冥想',
      meta: [
        { name: 'description', content: this.user.description},
        { name: 'keywords', content: this.user.seo}
      ]
    };
  },
  mounted() {
    this.xqsj()
  },
  methods:{
     xqsj(){
       if (this.type == 1){

         let list ={id:this.id.split('.')[0]}
         mes_classify_list_xq(list).then((res)=>{
           if (res.status == 1){
             var richtext=  res.data.cont;
             const regex = new RegExp('<img', 'gi');
             res.data.cont = richtext.replace(regex, `<img style="max-width: 100%;"`);
             this.list =res.data
           }
         })
       }else{
         let list ={id:this.id.split('.')[0]}
         gfhd_list_xq(list).then((res)=>{
           if (res.status == 1){
             var richtext=  res.data.cont;
             const regex = new RegExp('<img', 'gi');
             res.data.cont = richtext.replace(regex, `<img style="max-width: 100%;"`);
             this.list =res.data
           }
         })
       }
     }
  }
}
</script>

<style>

.cont_info span:has(img){
  width:100%;
  display: grid;

}
.cont_info img{
  max-width:100%!important;
  height: auto!important;
  display: block;
  margin: 0 auto;
}
</style>
<style scoped lang="scss">
.content{
  .cont_inform{
    width: 100%;
    height: 1.65rem;
    background: #EEEEEE;
    div{
      width: 100%;
      margin:0 auto;
      height: 1.65rem;
      line-height: 1.65rem;
      img{
        width: 0.53rem;
        height: 0.65rem;
        vertical-align: sub;
        margin-right: 9px;
        margin-left: 0.63rem;
        position: relative;
        top: -0.08rem;
      }
      font-weight: 500;
      font-size: 0.7rem;
      color: #666666;
      span{
        color: #222222;
      }
    }
  }
  .cont_policy{
    width: 17.6rem;
    background: #FFFFFF;
    margin: 0 auto;
    padding-top: 1px;
    .cont_title{
      font-weight: 800;
      font-size: 0.9rem;
      color: #333333;
      line-height: 1.2rem;
      margin-top: 1.3rem;
      text-align: center;
    }
    .cont_time{
      font-weight: 500;
      font-size: 0.6rem;
      color: #666666;
      line-height: 0.6rem;
      text-align: center;
      margin: 0.55rem auto 1.2rem;

    }
   .cont_info {
      width: 17.6rem;
      margin: 20px auto 2rem;
    }
    .about_xz{
      width: 300px;
      height: 66px;
      margin: 0 auto;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }

}
</style>